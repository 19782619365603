<template>
  <div class="absolute z-[101] left-50 h-full w-full flex flex-col items-center justify-center">
    <Loader :onlyLoader="false" title="" :text="text" />
  </div>
</template>

<script>
import Loader from "@/components/UI/Loader"

export default {
  name: "BlockUILoader",
  props: {
    text: String
  },
  components: {
    Loader
  }
}
</script>
