<template>
  <div class="h-screen">

    <!-- Header -->
    <div class="absolute left-5 pt-2.5">
      <div class="w-full mx-auto sm:max-w-md mb-7">
        <Brand size="normal" />
      </div>
    </div>

    <div class="absolute right-5 pt-2.5">
      <div class="flex flex-center">
        <SelectLangs />

        <ul class="flex items-center HeaderMainNav">
          <li class="relative" v-click-away="closeProfileMenu">
            <button @click="toggleProfileMenu" class="items-center min-h-full group align-middle mx-2 px-2 cursor-pointer text-sm text-gray-400 focus:outline-none hover:text-gray-100 transition" :class="[$store.state.Menu.isProfileMenuOpen ? 'open' : '']" aria-label="Account" aria-haspopup="true">
              <div class="flex items-center">
                <div class="avatar placeholder">
                  <div class="bg-gray-300 text-gray-700 rounded-full w-8 h-8 inline md:mr-2.5" v-if="$store.state.Auth.user">
                    <span><img :src="$store.state.Auth.user.avatar" class="rounded-full w-10" /></span>
                  </div>
                </div>
                <div class="flex flex-col items-start">
                  <p class="text-sm text-gray-700 group-hover:text-gray-600 hidden md:inline" v-if="$store.state.Auth.user">{{ $store.state.Auth.user.name }}</p>
                </div>
                <Icon icon="tabler:chevron-down" class="-mr-1 ml-2 h-5 w-5 text-sm hidden md:inline text-gray-400" />
              </div>
            </button>
            <TransitionFade2>
              <ul v-if="$store.state.Menu.isProfileMenuOpen" class="transition-all z-10 ease-in-out absolute right-2.5 w-56 px-2 py-2 mt-2 space-y-2 text-gray-600 bg-white border border-gray-100 rounded-md shadow-md dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700" aria-label="submenu">
                <li class="flex">
                  <button @click="logOut" class="inline-flex items-center w-full px-2 py-2 text-sm font-normal transition-colors duration-150 rounded-md hover:bg-red-100 hover:bg-opacity-80 logout">
                    <Icon icon="tabler:power" class="w-4 h-4 mr-3" />
                    <span>{{ $t("navbar.logOut") }}</span>
                  </button>
                </li>
              </ul>
            </TransitionFade2>
          </li>
        </ul>
      </div>
    </div>

    <!-- Onboarding actions -->
    <div v-if="!form.successful" class="flex-col-center justify-center">
      <div class="w-full my-5 md:my-20">
        <!-- Form -->
        <div class="w-full mx-auto sm:max-w-md px-5 sm:p-0">
          <div class="w-full mx-auto sm:rounded-lg md:max-w-lg mb-4 text-center">
            <h1 class="font-semibold text-lg lg:text-xl">{{ $t("register.onboarding.title") }} 👋</h1>
            <p class="font-md text-gray-400">{{ $t("register.onboarding.subtitle") }}.</p>
          </div>
          <Card>
            <template #title>
              <div class="mx-6 pt-4">
                <!-- Form title -->
                <h2 class="text-xl font-semibold">{{ $t("register.onboarding.completeProfile") }}</h2>
              </div>
            </template>
            <template #content>
              <BlockUI :blocked="formValues.busy || formUserValues.busy" :baseZIndex="100" :autoZIndex="false">
                <BlockUILoader v-if="formValues.busy || formUserValues.busy" :text="$t('loadingData')" />

                <div class="flex flex-col w-full">
                  <form @submit.prevent="submit" @keydown="form.onKeydown($event)">
                    <!-- Email field -->
                    <div class="form-group">
                      <label for="email" class="form-label">{{ $t("email") }} <LabelRequired /></label>
                      <InputText v-model="user.email" id="email" name="email" type="email" class="p-inputtext-sm" disabled />
                    </div>

                    <!-- Name field -->
                    <div class="form-group">
                      <label for="name" class="form-label">{{ $t("name") }} <LabelRequired /></label>
                      <InputText v-model="form.name" id="name" name="name" type="text" class="p-inputtext-sm" />
                      <HasError :form="form" field="name" />
                    </div>

                    <!-- Language field -->
                    <div class="form-group">
                      <label for="language" class="form-label">{{ $t("register.onboarding.language") }} <LabelRequired /></label>
                      <Dropdown v-model="form.config.language" :options="languageOptions" optionLabel="name" optionValue="code" dataKey="code" placeholder="--SELECCIONE--" class="p-inputtext-sm shadow-sm w-full" />
                      <HasError :form="form" field="config.language" />
                    </div>

                    <!-- Role field -->
                    <div class="form-group">
                      <label for="role" class="form-label">{{ $t("register.onboarding.role") }} <LabelRequired /></label>
                      <Dropdown v-model="form.company_rol_id" :options="roleOptions" optionLabel="name" optionValue="id" dataKey="id" placeholder="--SELECCIONE--" class="p-inputtext-sm shadow-sm w-full" />
                      <HasError :form="form" field="company_rol_id" />
                    </div>

                    <!-- Form submit button -->
                    <Button type="submit" :label="$t('register.onboarding.saveProfile')" :loading="form.busy" iconPos="right" class="p-button-primary p-button-lg w-full" />

                    <!-- Form error message -->
                    <HasError :form="form" field="error" />
                  </form>
                </div>
              </BlockUI>
            </template>
          </Card>
        </div>
      </div>
    </div>

    <!-- Onboarding success -->
    <div v-else class="flex-col-center justify-center h-full">
      <Loader :onlyLoader="false" title="" :text="$t('savedDataRedirecting')" />
    </div>
  </div>
</template>

<script>
// Framework components
import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import { HasError } from "vform/src/components/tailwind"
import Card from "primevue/card"
import Button from "primevue/button"
import InputText from "primevue/inputtext"
import Dropdown from "primevue/dropdown"
import BlockUI from "primevue/blockui"
import { Icon } from "@iconify/vue"

// App components
import TransitionFade2 from "@/components/TransitionFade2"
import BlockUILoader from "@/components/UI/BlockUILoader"
import Loader from "@/components/UI/Loader"
import Brand from "@/components/Brand"
import SelectLangs from "@/components/SelectLangs"
import LabelRequired from "@/components/LabelRequired"

export default {
  name: "Onboarding",

  components: {
    // Framework components
    Icon,
    Card,
    Button,
    InputText,
    Dropdown,
    HasError,
    BlockUI,
    // App components
    Brand,
    SelectLangs,
    LabelRequired,
    BlockUILoader,
    Loader,
    TransitionFade2
  },

  data() {
    return {
      token: this.$route.query?.access_token || localStorage.getItem("Token"),
      user: {},
      languageOptions: [
        { code: "es", name: "Español" },
        { code: "en", name: "English" }
      ],
      roleOptions: [],
      form: new FormClient({
        name: "",
        config: {
          project_default_id: null,
          language: null
        },
        company_rol_id: null
      }),
      formValues: new FormClient({}),
      formUserValues: new FormClient({})
    }
  },

  mounted() {
    if (!this.token || this.token == "null") {
      this.$router.push({ name: "Login" })
    }

    this.getFormValues()
    this.login(this.token)
  },

  methods: {
    async getFormValues() {
      let response = await this.formValues.get(ApiRoute.utilities.roles)
      this.roleOptions = response.data.data
    },

    async login(token) {
      await this.$store.dispatch("Auth/setToken", token)
      const response = await this.formUserValues.get(ApiRoute.auth.profile)
      await this.$store.dispatch("Auth/setUser", response.data)
      this.user = response.data.data

      // Fix for MailUp social login
      const regex = new RegExp(".*representaciones digitales.*", "i")
      if (regex.test(this.user.name)) {
        this.user.name = ""
      }

      // Set values to the user
      this.form.name = this.user.name
      this.form.company_rol_id = this.user.company_rol_id || null
      this.form.config = { ...this.user.config }

      if (!this.form.config.language) {
        this.form.config.language = this.$i18n.locale
      }
    },

    async submit() {
      await this.form.put(ApiRoute.user.update)
      this.$router.push({ name: "Onboarding redirect" })
    },

    toggleProfileMenu() {
      if (this.$store.state.Menu.isProfileMenuOpen) {
        this.closeProfileMenu()
      } else {
        this.openProfileMenu()
      }
    },

    openProfileMenu() {
      this.$store.dispatch("Menu/openProfileMenu")
    },

    closeProfileMenu() {
      this.$store.dispatch("Menu/closeProfileMenu")
    },

    getProject(id) {
      this.$store.dispatch("Projects/getProject", id)
    },

    logOut() {
      this.$store.dispatch("Auth/requestLogout")
    }
  }
}
</script>
