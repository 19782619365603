<template>
  <div>
    <Dropdown v-model="$store.state.Langs.selected" :options="langAvailables" optionLabel="name" class="p-inputtext-sm shadow-sm w-full">
      <!-- Menu selected -->
      <template #value="slotProps">
        <div class="country-item">
          <Icon v-if="slotProps.value.flag == 'ES'" icon="flagpack:es" class="country-flag" />
          <Icon v-if="slotProps.value.flag == 'US'" icon="flagpack:us" class="country-flag" />
          <Icon v-if="slotProps.value.flag == 'PT'" icon="flagpack:pt" class="country-flag" />
          <span class="country-label">{{ slotProps.value.name }}</span>
        </div>
      </template>
      <!-- Menu -->
      <template #option="slotProps">
        <div class="country-item" @click="handleLangs(slotProps.option.lang)">
          <Icon v-if="slotProps.option.flag == 'ES'" icon="flagpack:es" class="country-flag" />
          <Icon v-if="slotProps.option.flag == 'US'" icon="flagpack:us" class="country-flag" />
          <Icon v-if="slotProps.option.flag == 'PT'" icon="flagpack:pt" class="country-flag" />
          <span class="contry-label">{{ slotProps.option.name }}</span>
        </div>
      </template>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown"
import { Icon } from "@iconify/vue"

export default {
  name: "SelectLangs",
  components: {
    Dropdown,
    Icon
  },
  props: {
    showLangs: {
      type: Array,
      default: function () {
        return ["es", "en", "pt"]
      }
    }
  },
  computed: {
    langAvailables() {
      return this.$store.state.Langs.availables.filter((langAvailable) => {
        if (this.showLangs.indexOf(langAvailable.lang) > -1) {
          return true
        }

        return false
      })
    }
  },
  methods: {
    closeMenuLangs() {
      this.$store.dispatch("Langs/closeMenuLangs")
    },
    handleLangs(lang) {
      this.$store.dispatch("Langs/changeAppLang", lang)
      this.$i18n.locale = lang
      this.$moment.locale(lang)
    }
  }
}
</script>

<style scoped>
.country-item {
  @apply w-full flex justify-start items-center;
}
.country-item .country-label {
  @apply hidden sm:block font-normal text-sm;
}

.country-item .country-flag {
  @apply w-5 h-5 mr-2 rounded-md;
}
</style>
